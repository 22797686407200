.App {
  text-align: center;
  margin: 2em auto;
  max-width: 1024px;
  font-family: "Press Start 2P", cursive;
  color: whitesmoke;
}

header {
  border: 5px solid white;
}

input {
  color: lightgreen;
  background-color: black;
  font-family: inherit;
  font-size: 1.1em;
  width: 80%;
}

.error {
  background-color: red;
  padding: 1em;
  margin-top: 1em;
}

.description{
  padding: 1em;
  margin-top: 1em;
}

.titleComponent {
  font-size: 1.5em;
  line-height: 2em;
}

#binaryContainer {
  grid-area: binary;
}

#decimalContainer {
  grid-area: decimal;
}

.block {
  padding: 15px;
  border: 5px solid white;
  border-top: 0px;
}

#decimalComponent{
  font-size: 3em;
  color: lightgreen;
  margin-top: 0.2em;
}

@media (max-width: 500px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 500px 500px;
    grid-template-areas: "binary" "decimal";
  }
}

@media (min-width: 501px) {
  #container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "binary decimal";
  }
  #decimalContainer{
    border-left: 0px;
  }
}
